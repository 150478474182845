import {
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  MenuItemDef,
  RowDragEvent,
} from 'ag-grid-community'
import { icons } from 'eva-icons'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { navReportsColumns } from '../../../../../infrastructure/constant/columns/navigationReportsColumns'
import { IMenuItem } from '../../../../../infrastructure/interfaces/settings'
import { UserRoles } from '../../../../../infrastructure/interfaces/users'
import { settingsService } from '../../../../../infrastructure/services/settingsService'
import storageService from '../../../../../infrastructure/services/storageService'
import { RootState } from '../../../../../infrastructure/store'
import { setMenuSignal } from '../../../../../infrastructure/store/sidebar/sidebarSlice'
import { checkPermissions } from '../../../../../infrastructure/utils/permissions'

const useNavReportList = (id: string) => {
  const [gridApi, setGridApi] = useState<GridApi>()
  const [deleteModal, setDeleteModal] = useState<{
    open: boolean
    id?: number
  }>({
    open: false,
  })
  const [loadedData, setLoadedData] = useState<boolean>(false)
  const [perPage, setPerPage] = useState<number>(500)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [rowData, setRowData] = useState<any[]>([])
  const [currentEntity, setCurrentEntity] = useState<string>('')
  const [selectedIcon, setSelectedIcon] = useState<string>('')
  const [formFolder, setFormFolder] = useState<{
    label: string
    icon: string
    id?: string
  }>({
    label: '',
    icon: '',
    id: '',
  })
  const [showAddReportModal, setShowAddReportModal] = useState<{
    open: boolean
    title: string
    type?: string
    action?: string
  }>({
    open: false,
    title: '',
  })
  const [reportsAvailable, setReportsAvailable] = useState<any[]>([])
  const [cmsAvailable, setCmsAvailable] = useState<any[]>([])
  const [reportsSelected, setReportsSelected] = useState<any>(null)
  const [labelReport, setLabelReport] = useState<string>('')
  const user: any = useSelector((state: RootState) => state.auth)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleFormFolder = (field: string, value: string) => {
    setFormFolder((prev) => ({ ...prev, [field]: value }))
  }

  const handleIconSelect = (iconName: string) => {
    setSelectedIcon(iconName)
  }

  const handleSelect = ({ selected, type }: any) => {
    const id = selected.value
    let options = type === 'report' ? reportsAvailable : cmsAvailable
    const report = options.find((report) => report.id === id)
    report.type = type
    setReportsSelected(report)
  }

  const handleNameChangeReport = (event: any) => {
    const name = event.target.value
    setLabelReport(name)
  }

  const onDeleteMenuItem = (id: number) => {
    settingsService.deleteMenuItem(id).then((response) => {
      if (response.status === 200) {
        getMenuList()
        getMenuSidebar()
        setDeleteModal({ open: false, id: 0 })
      }
    })
  }

  const onAddNewReport = ({ type }: any) => {
    const newReport: IMenuItem = {
      label: labelReport,
      company_id: parseInt(id),
      icon: reportsSelected?.icon,
      parent_menu_id: undefined,
      type: reportsSelected?.type,
      entity_id: reportsSelected?.id,
      weight: rowData?.length || 0,
      slug: reportsSelected?.slug,
    }
    if (!labelReport) {
      toast.error(`${t('messages.menu-nombre')}`, {
        position: 'top-center',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        autoClose: 5000,
      })
    }

    if (!reportsSelected || !labelReport) return
    settingsService.postMenuItems(newReport).then((response: any) => {
      if (response.status === 200) {
        toast.success(`${t('messages.menu-añadido')}`, {
          position: 'top-center',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          autoClose: 5000,
        })
        getMenuList()
        setShowAddReportModal({ open: false, title: '', type: '' })
        setReportsSelected(null)
        getMenuSidebar()
      }
    })
  }

  const handleFolderAction = (action: 'EDIT' | 'CREATE') => {
    const folderData = {
      id: formFolder.id,
      label: formFolder.label,
      weight: rowData?.length || 0,
      icon: formFolder.icon,
      company_id: parseInt(id),
      parent_menu_id: undefined,
      type: 'folder',
    }

    if (!formFolder.label) {
      toast.error(`${t('messages.menu-nombre')}`, {
        position: 'top-center',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        autoClose: 5000,
      })
      return
    }

    const request =
      action === 'EDIT'
        ? settingsService.putMenuItems(folderData.id, folderData)
        : settingsService.postMenuItems(folderData)

    request.then((response: any) => {
      if (response.status === 200) {
        toast.success(
          action === 'EDIT'
            ? `${t('messages.folder-actualizado')}`
            : `${t('messages.folder-creado')}`,
          {
            position: 'top-center',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            autoClose: 5000,
          }
        )
        getMenuList()
        setShowAddReportModal({ open: false, title: '', type: '' })
        setFormFolder({ id: '', label: '', icon: '' })
        getMenuSidebar()
      }
    })
  }

  const getAllEntities = () => {
    settingsService
      .getCompanies({ page: currentPage, per_page: perPage })
      .then((response) => {
        const { items } = response.data
        const entity = items.find((company: any) => company.id === parseInt(id))
        setCurrentEntity(entity.name)
      })
  }

  const getMenuList = () => {
    settingsService.getMenuItems({ id }).then((response) => {
      const { data } = response
      setRowData(data)
    })
  }

  const getCmsList = () => {
    settingsService
      .getPages({ page: currentPage, per_page: perPage })
      .then((response) => {
        const { items } = response.data
        const cms = items.filter((cms: any) => cms.company.id === parseInt(id))
        setCmsAvailable(cms)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getReports = () => {
    settingsService
      .getReports({
        page: currentPage,
        per_page: perPage,
      })
      .then((response) => {
        const { items } = response.data

        const reports = items.filter(
          (report: any) => report.company.id === parseInt(id)
        )
        setReportsAvailable(reports)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    const handleAddFolder = (event: any) => {
      if (event.detail.open) {
        setShowAddReportModal({
          open: true,
          type: 'folder',
          title: 'Añadir Carpeta',
        })
      }
    }

    window.addEventListener('addFolder', handleAddFolder)

    return () => {
      window.removeEventListener('addFolder', handleAddFolder)
    }
  }, [gridApi])

  useEffect(() => {
    const handleAddReport = (event: any) => {
      if (event.detail.open) {
        getReports()
        setShowAddReportModal({
          open: true,
          type: 'report',
          title: 'Añadir Informe',
        })
      }
    }

    window.addEventListener('addReport', handleAddReport)

    return () => {
      window.removeEventListener('addReport', handleAddReport)
    }
  }, [])
  useEffect(() => {
    const handleAddCms = (event: any) => {
      if (event.detail.open) {
        getCmsList()
        setShowAddReportModal({
          open: true,
          type: 'page',
          title: 'Añadir CMS',
        })
      }
    }

    window.addEventListener('addCms', handleAddCms)

    return () => {
      window.removeEventListener('addCms', handleAddCms)
    }
  }, [])

  const getMenuSidebar = () => {
    dispatch(setMenuSignal(true))
  }

  const focusAndEditNewFolder = (gridApi: any, id: any) => {
    setTimeout(() => {
      const rowIndex = gridApi.getRowNode(id)?.rowIndex
      if (rowIndex !== undefined) {
        gridApi.ensureIndexVisible(rowIndex, 'top')
        gridApi.setFocusedCell(rowIndex, 'label')
        gridApi.startEditingCell({
          rowIndex,
          colKey: 'label',
        })
      }
    }, 0)
  }

  const onGridReady = (params: GridReadyEvent) => {
    setLoadedData(true)
    if (id) {
      getAllEntities()
      getMenuList()
    }
    setGridApi(params.api)
  }

  const onRowDragEnd = (event: RowDragEvent) => {
    const { overIndex, nodes } = event
    const movedNode = nodes[0]
    const updatedData = rowData.slice()
    updatedData.splice(updatedData.indexOf(movedNode.data), 1)
    updatedData.splice(overIndex, 0, movedNode.data)

    const reorderData = updatedData.map((item, index) => {
      const menuData: any = {
        id: item.id,
        weight: index,
      }

      if (item.submenu && item.submenu.length > 0) {
        menuData.submenu = item.submenu.map(
          (subItem: any, subIndex: number) => {
            return {
              id: subItem.id,
              weight: subIndex,
              parent_menu_id: item.id,
            }
          }
        )
      }

      return menuData
    })

    settingsService.reorderMenuItems(reorderData).then((response) => {
      if (response.status === 200) {
        getMenuList()
        getMenuSidebar()
        setRowData(updatedData)
      }
    })
  }

  const getContextMenu = (row: GetContextMenuItemsParams): MenuItemDef[] => {
    if (!row.value) return []
    const items: MenuItemDef[] = []

    let currentUser = user?.user || storageService.getUser()

    if (
      checkPermissions(['users.*', 'users.edit'], currentUser?.permissions) ||
      currentUser?.role?.name === UserRoles.ADMIN
    ) {
      items.push({
        name: t('titles.Editar'),
        action: () => {
          if (row.node && row.node.data.type === 'folder') {
            setShowAddReportModal({
              open: true,
              action: 'EDIT',
              title: 'titles.Editar Carpeta',
              type: 'folder',
            })
            setFormFolder({
              label: row.node.data.label,
              icon: row.node.data.icon,
              id: row.node.data.id,
            })
          } else if (row.node && row.node.data.type === 'report') {
            focusAndEditNewFolder(gridApi, row.node.id)
          }
        },
        cssClasses: ['tw-cursor-pointer'],
      })
    }
    if (
      checkPermissions(['users.*', 'users.delete'], currentUser?.permissions) ||
      currentUser?.role?.name === UserRoles.ADMIN
    ) {
      items.push({
        name: t('titles.Eliminar'),
        action: () => {
          setDeleteModal({
            open: true,
            id: row?.node?.data.id,
          })
        },
        cssClasses: ['tw-text-alert', 'tw-cursor-pointer'],
      })
    }
    return items
  }

  const reportsAvaillables = useMemo(() => {
    // Filtrar los reportes que ya están en rowData
    const existingReports = rowData?.map((item) => item?.report?.id)
    return reportsAvailable
      .filter((report) => !existingReports.includes(report.id))
      .map((report) => {
        return {
          value: report.id,
          label: report.name,
        }
      })
  }, [reportsAvailable, rowData])

  const cmsAvaillables = useMemo(() => {
    // Filtrar los CMS que ya están en rowData
    const existingCms = rowData.map((item) => item?.page?.id)
    return cmsAvailable
      .filter((cms) => !existingCms.includes(cms.id))
      .map((cms) => {
        return {
          value: cms.id,
          label: cms.title,
        }
      })
  }, [cmsAvailable, rowData])

  const handleCloseModal = () => {
    setShowAddReportModal({ open: false, title: '', type: '' })
    setReportsSelected(null) // Limpiar selección al cerrar el modal
  }

  const iconOptions = useMemo(() => {
    return Object.keys(icons).map((iconName) => ({
      value: iconName,
      label: iconName,
      svg: icons[iconName].toSvg({ width: 20, height: 20 }),
    }))
  }, [])

  const onCellEditingStopped = (event: any) => {
    const { data, oldValue, colDef } = event
    const newValue = data[colDef.field]
    if (newValue === oldValue) {
      return
    }

    if (data.type === 'report' || data.type === 'page') {
      const newData = {
        id: data.id,
        company_id: parseInt(id),
        entity_id: data.report?.id || data.page?.id,
        label: data.label,
        icon: data.icon,
        slug: data.slug,
        type: data.type,
        weight: data.weight,
      }

      settingsService.putMenuItems(newData.id, newData).then((response) => {
        if (response.status === 200) {
          toast.success(`${t('messages.menu-actualizado')}`, {
            position: 'top-center',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            autoClose: 5000,
          })
          getMenuList()
          getMenuSidebar()
        }
      })
    }
  }

  const menuColumns = useMemo(() => {
    return navReportsColumns.map((column: any) => {
      if (column.field === 'icon') {
        return {
          ...column,
          cellRenderer: (params: any) => {
            const iconName = params.value
            // si el icon no se encuentra en la lista de iconos devolver null
            const icon = icons?.[iconName]
            if (!icon) return null
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: icon?.toSvg({
                    width: 17,
                    height: 17,
                  }),
                }}
                className="tw-flex-shrink-0 tw-p-1 tw-rounded-md tw-shadow-md tw-bg-[#eaeaea] "
              />
            )
          },
        }
      }
      return column
    })
  }, [])

  return {
    cmsAvaillables,
    currentEntity,
    currentPage,
    deleteModal,
    formFolder,
    getContextMenu,
    gridApi,
    handleCloseModal,
    handleFolderAction,
    handleFormFolder,
    handleIconSelect,
    handleNameChangeReport,
    handleSelect,
    iconOptions,
    loadedData,
    menuColumns,
    onAddNewReport,
    onCellEditingStopped,
    onDeleteMenuItem,
    onGridReady,
    onRowDragEnd,
    perPage,
    reportsAvailable: reportsAvaillables,
    rowData,
    selectedIcon,
    selectedOption: reportsSelected,
    setDeleteModal,
    setReportsSelected,
    setRowData,
    setShowAddReportModal,
    showAddReportModal,
    totalPages,
  }
}

export default useNavReportList
