import Collapse from '@mui/material/Collapse'
import 'rc-tooltip/assets/bootstrap.css'
import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { settingsMenu } from '../../../infrastructure/constant/menu/settingsMenu'
import useWindowSize from '../../../infrastructure/hooks/useWindowSize'
import {
  IMenuFixItem,
  IMenuItem,
} from '../../../infrastructure/interfaces/sidebar'
import { IUserItem, UserRoles } from '../../../infrastructure/interfaces/users'
import { api } from '../../../infrastructure/services/api'
import storageService from '../../../infrastructure/services/storageService'
import { RootState } from '../../../infrastructure/store'
import {
  setMenuSignal,
  setMenu as setMenuToStore,
  setOpen,
  setSelectedMenu,
} from '../../../infrastructure/store/sidebar/sidebarSlice'
import { userTypeGuard } from '../../../infrastructure/utils/common'
import { checkPermissions } from '../../../infrastructure/utils/permissions'
import Logo from '../Logo/Logo'
import Paragraphs from '../Paragraphs/Paragraphs'
import Menu from './Menu/Menu'
import MenuItem from './Menu/MenuItem'
import SubMenu from './Menu/SubMenu'
import ToggleButton from './ToggleButton.component'

interface SidebarProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setIsLoading: (isLoading: boolean) => void
  isLoading: boolean
  auth: any
  entity: any
}

export interface IPositionSubMenu {
  left: number
  top: number
  submenu: IMenuItem[] | IMenuFixItem[]
}

const Sidebar = (props: SidebarProps) => {
  const { t } = useTranslation()
  const auth = useSelector((state: RootState) => state.auth)
  const { isOpen, setIsOpen, isLoading, setIsLoading, entity } = props
  const [userData, setUserData] = useState<IUserItem>()
  const [menu, setMenu] = useState<IMenuItem[]>([])
  const [positionSubMenu, setPositionSubMenu] =
    useState<IPositionSubMenu | null>(null)
  const [, setShowTitles] = useState<boolean>(isOpen)
  const selectedMenu = useSelector(
    (state: RootState) => state.sidebar.selectedMenu
  )
  const selectedEntity = useSelector(
    (state: RootState) => state.multitenant.entity
  )
  const { refetchMenu } = useSelector((state: RootState) => state.sidebar)
  const [, setShowSubMenuSettings] = useState<boolean>(false)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const windowSize = useWindowSize()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (refetchMenu) {
      setIsLoading(true)
      getMenus()
      dispatch(setMenuSignal(false))
    }
  }, [refetchMenu])

  useEffect(() => {
    if (auth) {
      if ((auth.isAuthenticated && auth.user) || storageService.getUser()) {
        setUserData(auth.user! || storageService.getUser())
        setIsLoading(false)
      }
    }
  }, [auth, selectedEntity])

  useEffect(() => {
    setIsLoading(true)
    getMenus()
  }, [])

  useMemo(() => {
    if (windowSize.width <= 768 && isOpen) {
      setIsOpen(false)
      setShowSubMenuSettings(false)
      dispatch(setOpen(false))
      setShowTitles(false)
    }
  }, [windowSize])

  let stopMenu = false
  const getMenus = () => {
    if (
      (!userData ||
        !checkPermissions(
          ['menus.*', 'menus.view', 'menus.list'],
          userData.permissions
        )) &&
      userTypeGuard(userData) &&
      userData.role.name !== UserRoles.ADMIN
    ) {
      stopMenu = true
      setIsLoading(false)
      return
    }

    if (stopMenu) return
    stopMenu = true
    api
      .get('/menu')
      .then((res: { data: IMenuItem[] }) => {
        let tempMenu = res.data?.map((menu: IMenuItem) => ({
          ...menu,
          collapsed: true,
          children: menu.submenu.map((submenu: IMenuItem) => ({
            ...submenu,
            canHaveChildren: false,
          })),
        }))
        setMenu(tempMenu)
        dispatch(setMenuToStore(tempMenu))

        const homePage = res.data.find((item) => item.page?.is_home)
        if (
          homePage &&
          !selectedMenu &&
          (location.pathname === '/' || location.pathname === '/home')
        ) {
          dispatch(setSelectedMenu(homePage))
        }
      })
      .catch(() => {
        setMenu([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const selectMenu = (link: any) => {
    if (link.type === 'folder' && link.submenu.length === 0) return
    if (
      link.slug.startsWith('settings') ||
      link.slug.startsWith('route-planner')
    ) {
      setTimeout(() => {
        return navigate(link.slug)
      }, 300)
    } else {
      if (link.page) {
        if (!link.page.has_widgets && link.submenu.length) {
          let firstSubmenu = link.submenu[0]
          dispatch(setSelectedMenu(firstSubmenu))
          if (firstSubmenu.page) {
            return navigate('/pages/' + firstSubmenu.page.slug)
          }
          if (firstSubmenu.report) {
            return navigate('/reports/' + firstSubmenu.report.slug)
          }
        }
        return navigate('/pages/' + link.page.slug)
      } else if (link.report) {
        return navigate('/reports/' + link.report.slug)
      } else {
        navigate('/home')
        return dispatch(setSelectedMenu(link))
      }
    }
  }

  useEffect(() => {
    showMenuTitles()
  }, [isOpen])

  const showMenuTitles = () => {
    if (isOpen) {
      setTimeout(() => {
        setShowTitles(isOpen)
      }, 250)
    } else {
      setShowTitles(false)
    }
  }

  const checkAdminRole = () => {
    if (userData?.role.name === UserRoles.MODERATOR) {
      // check if user has permissions to see settingsMenu children (if no any children, then hide settingsMenu)
      const settingsMenuChildren = settingsMenu[0].submenu || []
      const childrenMenus = settingsMenuChildren?.filter((menu) =>
        checkPermissions(
          menu.permissions || [''],
          userData?.permissions || ['']
        )
      )

      if (childrenMenus.length === 0) {
        return false
      }
    }

    return (
      userData?.role?.name === UserRoles.ADMIN ||
      userData?.role?.name === UserRoles.MODERATOR
    )
  }

  const isPageHome = () => {
    return menu.find((item) => item.page?.is_home)
  }

  const handleToggle = () => {
    setIsOpen(!isOpen)
    dispatch(setOpen(!isOpen))
  }

  const calculateRelativePosition = (
    event: MouseEvent<HTMLElement> | null,
    submenu: IMenuItem[] | IMenuFixItem[] | null | undefined
  ) => {
    if (!sidebarRef.current) return null
    if (!event || !submenu) {
      setPositionSubMenu(null)
      return
    }

    const rect = event.currentTarget.getBoundingClientRect() // Obtener las dimensiones del elemento actual
    const parentRect = sidebarRef.current.getBoundingClientRect() // Obtener las dimensiones del elemento padre

    const xPos = 80 // Posición X relativa al borde izquierdo del elemento padre
    const yPos = rect.bottom - parentRect.top - 60 // Posición Y relativa a la parte inferior del elemento padre

    setPositionSubMenu({ top: yPos, left: xPos, submenu })
  }

  return (
    <>
      <div
        ref={sidebarRef}
        className={`tw-relative tw-transition-width tw-duration-300 ${
          isOpen
            ? 'tw-min-w-[275px] tw-w-[275px]'
            : 'tw-min-w-[75px] tw-w-[75px]'
        } tw-max-w-[275px] tw-h-[92vh]`}
        style={{
          backgroundColor:
            selectedEntity && selectedEntity.tertiary_color
              ? selectedEntity.tertiary_color
              : 'white',
        }}
      >
        {isLoading && (
          <div className="tw-flex tw-flex-col tw-absolute tw-top-0 tw-left-0 tw-bg-white tw-bg-opacity-80 tw-h-full tw-w-full tw-z-10 tw-items-center tw-justify-center">
            <Logo width={100} />
            <Paragraphs>{t('messages.Cargando')}</Paragraphs>
          </div>
        )}

        <Collapse in={isOpen} collapsedSize="75px" orientation="horizontal">
          <div
            className="tw-overflow-hidden tw-w-full tw-h-[100vh] tw-absolute tw-top-0 tw-left-0 tw-bottom-0 tw-pb-[1.2rem]"
            style={{
              backgroundColor:
                selectedEntity && selectedEntity.tertiary_color
                  ? selectedEntity.tertiary_color
                  : 'white',
            }}
          >
            <div className="tw-relative tw-w-full tw-h-full tw-transition-all tw-duration-300 tw-mt-4">
              <div
                className={`tw-flex tw-items-start tw-justify-center tw-sticky tw-top-0 tw-cursor-pointer ${
                  isOpen
                    ? 'tw-w-[275px] tw-p-[20px_37px_20px_37px] tw-h-[125px]'
                    : 'tw-w-[75px] tw-p-[12px_12px_0_12px] tw-h-[75px]'
                }`}
                style={{
                  backgroundColor:
                    selectedEntity && selectedEntity.tertiary_color
                      ? selectedEntity.tertiary_color
                      : 'white',
                }}
                onClick={() =>
                  selectMenu({
                    slug: '/home',
                    title: t('dashboard'),
                    icon: 'dashboard',
                  })
                }
              >
                <Logo
                  width={isOpen ? 170 : 40}
                  mode={isOpen ? 'black' : 'small'}
                  entityLogo={entity?.logo}
                />
              </div>

              <div className="tw-overflow-y-auto tw-overflow-x-hidden tw-h-[75vh] tw-border-r tw-border-gray-100">
                {!isLoading && menu && !isPageHome() && (
                  <MenuItem
                    key={0}
                    item={{
                      id: 0,
                      slug: '/home',
                      label: t('Dashboard'),
                      icon: 'home-outline',
                      weight: 0,
                      submenu: [],
                    }}
                    isOpen={isOpen}
                    selectedMenu={selectedMenu}
                    positionSubMenu={positionSubMenu}
                    selectMenu={selectMenu}
                    calculateRelativePosition={calculateRelativePosition}
                  />
                )}

                <Menu
                  menu={menu || []}
                  selectedMenu={selectedMenu as IMenuItem}
                  isOpen={isOpen}
                  selectMenu={selectMenu}
                  calculateRelativePosition={calculateRelativePosition}
                  positionSubMenu={positionSubMenu}
                />

                {checkAdminRole() &&
                  settingsMenu.map((link) => (
                    <MenuItem
                      key={link.slug}
                      item={link}
                      isOpen={isOpen}
                      selectedMenu={selectedMenu}
                      selectMenu={selectMenu}
                      calculateRelativePosition={calculateRelativePosition}
                      positionSubMenu={positionSubMenu}
                    />
                  ))}
              </div>
            </div>
          </div>
          {!isLoading && (
            <ToggleButton isOpen={isOpen} handleToggle={handleToggle} t={t} />
          )}
        </Collapse>

        {!isOpen && positionSubMenu && (
          <div
            className="tw-z-[5] tw-absolute tw-bg-white  tw-text-brandMedium tw-rounded-[4px] tw-p-[4px] tw-w-[204px] 
            "
            style={{
              top: `${positionSubMenu.top}px`,
              left: `${positionSubMenu.left}px`,
              minWidth: '200px',
            }}
          >
            {/* tw-shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] */}
            <SubMenu
              submenu={positionSubMenu.submenu}
              isOpen={isOpen}
              selectedMenu={selectedMenu}
              selectMenu={selectMenu}
              dispatch={dispatch}
              toggleSubMenu={() => setPositionSubMenu(null)}
              parentId={0}
            />
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  entity: state.multitenant.entity,
})

export default connect(mapStateToProps)(Sidebar)
