import styled from 'styled-components'

export const NewComponentBottom = styled.div`
  position: absolute;
  bottom: 20px;
  right: 30px;
  display: block;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
