import { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { UserRoles } from '../../../../infrastructure/interfaces/users'
import { RootState } from '../../../../infrastructure/store'
import { checkPermissions } from '../../../../infrastructure/utils/permissions'
import '../../AgGrid/styles'
import Paragraphs from '../../Paragraphs/Paragraphs'
import { PrimaryButton } from '../../PrimaryButton/PrimaryButton'

type Props = {
  children: ReactNode
  path: string
  title: string
  fullTitle?: string | undefined
  showNewButton?: boolean
}

const SettingsLayout: FC<Props> = ({
  children,
  path,
  title,
  fullTitle = false,
  showNewButton,
}) => {
  const [pathBaseName, setPathBaseName] = useState<string>('')
  const [permissionPath, setPermissionPath] = useState<string[]>([])
  const user = useSelector((state: RootState) => state.auth.user)
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const pathSegments = location.pathname.split('/')
  const basePathName = pathSegments[2]

  useEffect(() => {
    if (location.pathname) {
      let permissionPath = location.pathname.split('settings/')[1]
      switch (permissionPath) {
        case 'cms':
          setPermissionPath(['pages.*', 'pages.create'])
          break
        case 'status':
          setPermissionPath(['status.*', 'status.create'])
          break
        default:
          setPermissionPath([`${permissionPath}.*`, `${permissionPath}.create`])
          break
      }
      setPathBaseName(location.pathname.split('settings/')[1])
    }
  }, [location.pathname])

  const isNavigationReportsPath = () => {
    return /\/navigation\/menu(\/|$)/.test(location.pathname)
  }
  const isNavigationFolderPath = () => {
    return /\/navigation\/menu\/[^/]+\/folder\/[^/]+(\/|$)/.test(
      location.pathname
    )
  }

  const renderArrow = () => (
    <Paragraphs size="sm" weight="bold">
      {'>'}
    </Paragraphs>
  )

  const renderBreadcrumbItem = (titleKey: string) => {
    // avoid id's in the breadcrumb
    if (titleKey.match(/^[0-9]+$/)) return null

    return (
      <Paragraphs size="sm" weight="bold">
        {t(`titles.${titleKey}`)}
      </Paragraphs>
    )
  }

  return (
    <div className="ag-container-layout tw-h-full tw-flex tw-flex-col tw-w-full ">
      <div
        className={`ag-layout-header tw-border-b-2 tw-pb-2 ${
          !pathBaseName.includes(path) ? 'tw-pb-4' : ''
        }
        `}
      >
        <div className="ag-layout-header-title tw-flex tw-justify-between tw-px-8 tw-items-center">
          <Paragraphs size="2xl" weight="bold" className="tw-pt-6 tw-pb-1">
            {fullTitle
              ? t('titles.' + fullTitle)
              : t('titles.Gestion de', { title })}
          </Paragraphs>
          <div className="tw-pt-4">
            {pathBaseName === 'users' ? (
              <Link to={location.pathname + path}>
                <PrimaryButton text="titles.Nuevo" type="button" />
              </Link>
            ) : !pathBaseName.includes('user') &&
              !pathBaseName.includes(path) &&
              (checkPermissions(permissionPath, user?.permissions || ['']) ||
                user?.role.name === UserRoles.ADMIN) ? (
              <Link to={location.pathname + path}>
                <PrimaryButton text="titles.Nuevo" type="button" />
              </Link>
            ) : isNavigationReportsPath() && !isNavigationFolderPath() ? (
              <div className="tw-flex tw-gap-4 tw-items-center ">
                <PrimaryButton
                  text="titles.Nueva carpeta"
                  type="button"
                  onClick={() => {
                    const event = new CustomEvent('addFolder', {
                      detail: {
                        open: true,
                      },
                    })
                    window.dispatchEvent(event)
                  }}
                />
                <PrimaryButton
                  text="titles.Añadir informe"
                  type="button"
                  onClick={() => {
                    const event = new CustomEvent('addReport', {
                      detail: {
                        open: true,
                      },
                    })
                    window.dispatchEvent(event)
                  }}
                />
                <PrimaryButton
                  text="Añadir CMS"
                  type="button"
                  onClick={() => {
                    const event = new CustomEvent('addCms', {
                      detail: {
                        open: true,
                      },
                    })
                    window.dispatchEvent(event)
                  }}
                />
              </div>
            ) : isNavigationFolderPath() ? (
              <div className="tw-flex tw-gap-4 tw-items-center ">
                <PrimaryButton
                  text="Añadir informe"
                  type="button"
                  onClick={() => {
                    const event = new CustomEvent('addReport', {
                      detail: {
                        open: true,
                        inFolder: true,
                      },
                    })
                    window.dispatchEvent(event)
                  }}
                />
                <PrimaryButton
                  text="titles.Añadir CMS"
                  type="button"
                  onClick={() => {
                    const event = new CustomEvent('addCms', {
                      detail: {
                        open: true,
                        inFolder: true,
                      },
                    })
                    window.dispatchEvent(event)
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="tw-flex tw-flex-row tw-items-start tw-px-8">
          {location.pathname.includes('settings') && (
            <div className="tw-flex tw-gap-2 tw-items-center">
              {renderBreadcrumbItem('Configuración')}
              {renderArrow()}
              {pathSegments.length > 2 && (
                <>
                  <Link
                    to={`/settings/${basePathName}`}
                    className="tw-cursor-pointer hover:tw-underline"
                  >
                    {renderBreadcrumbItem(basePathName)}
                  </Link>
                </>
              )}
              {pathSegments.length > 3 && renderArrow()}
              {pathSegments.length > 3 && (
                <>
                  {pathSegments.length > 4 ? (
                    <a
                      onClick={() => {
                        navigate(-1)
                      }}
                      className="tw-cursor-pointer hover:tw-underline"
                    >
                      {renderBreadcrumbItem(pathSegments[3])}
                    </a>
                  ) : (
                    renderBreadcrumbItem(pathSegments[3])
                  )}
                </>
              )}
              {pathSegments.length > 5 && renderArrow()}
              {pathSegments.length > 5 && renderBreadcrumbItem(pathSegments[4])}
              {pathSegments.length > 6 && renderBreadcrumbItem(pathSegments[5])}
            </div>
          )}
        </div>
      </div>
      <div
        className={`ag-layout-content  tw-h-full tw-overflow-auto tw-w-full`}
      >
        {children}
      </div>
    </div>
  )
}

export default SettingsLayout
