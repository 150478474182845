import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Editor } from '@tinymce/tinymce-react'
import Paragraphs from '../../../../components/commons/Paragraphs/Paragraphs'
import { IWidget } from '../../../../infrastructure/interfaces/settings'
import { settingsService } from '../../../../infrastructure/services/settingsService'
import StickySettingsBtnBottom from '../../../../components/commons/Layouts/settingsPages/StickySettingsBtnBottom'

const EditWidget = () => {
  const { t } = useTranslation()
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [widget, setWidget] = useState<IWidget | any>()
  const [data, setData] = useState<any>()
  const editorRef = useRef<any>(null)
  const apiKey = process.env.REACT_APP_TINYMCE_API

  let calling = false
  useEffect(() => {
    if (params && params.id && !calling) {
      getWidget(params.id)
      calling = true
    }
  }, [params])

  useEffect(() => {
    if (location && location['state']) {
      const state: any = location['state']
      state && setData(state.data)
    }
  }, [location])

  const getWidget = async (id: string) => {
    settingsService.getWidget(parseInt(id)).then((res) => {
      setWidget(res.data)
    })
  }

  const handleWidgetUpdate = async () => {
    const response = await settingsService.updateWidget({
      ...widget,
      page_id: data.id,
    })
    if (response.status === 200) {
      toast.success(t('messages.Contenido actualizado correctamente') as string, {
        position: toast.POSITION.TOP_CENTER,
      })

      navigate('/settings/cms/create-page', { state: { data }, replace: true })
    } else {
      toast.error(t('messages.Error al actualizar el contenido') as string, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  const uploadImage = async (image: string) => {
    const response = await settingsService.uploadWidgetImage(
      parseInt(params!.id!!),
      image
    )
    return response.data.url
  }

  return (
    <div className="tw-w-full tw-h-full tw-relative tw-pr-2">
      <StickySettingsBtnBottom onSave={handleWidgetUpdate}>
        <div className="tw-relative tw-h-full tw-overflow-auto">
          <div className="tw-border-b-2">
            <div className="tw-flex tw-justify-between tw-px-8 tw-relative">
              <Paragraphs size="xl" weight="bold" className="tw-py-4">
                {t('titles.Bloque CMS')}
              </Paragraphs>
            </div>
          </div>
          <div className="tw-p-8">
            <Editor
              apiKey={apiKey}
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={widget?.content || ''}
              init={{
                min_height: 300,
                menubar: false,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'table',
                  'code',
                  'help',
                  'wordcount',
                  'image',
                  'paste'
                ],
                language: 'es',
                file_picker_types: 'image',
                image_title: true,
                automatic_uploads: true,
                toolbar:
                  'undo redo | blocks | ' +
                  'code | formatselect |' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent image | ' +
                  'removeformat preview | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                file_picker_callback(callback, value, meta) {
                  if (meta.filetype === 'image') {
                    const input = document.createElement('input')
                    input.setAttribute('type', 'file')
                    input.setAttribute('accept', 'image/*')
                    input.onchange = async () => {
                      const file = input.files![0]
                      const reader = new FileReader()
                      reader.onload = async () => {
                        const id = `blobid${new Date().getTime()}`
                        const blobCache =
                          editorRef.current.editorUpload.blobCache
                        const base64 = reader.result as string
                        const blobInfo = blobCache.create(id, file, base64)
                        blobCache.add(blobInfo)
                        const url = await uploadImage(base64)
                        callback(url, { title: file.name })
                      }
                      reader.readAsDataURL(file)
                    }
                    input.click()
                  }
                },
              }}
              onEditorChange={(content: string) => {
                setWidget({
                  ...widget,
                  content,
                })
              }}
            />
          </div>
        </div>
      </StickySettingsBtnBottom>
    </div>
  )
}

export default EditWidget
